import type { StackProps, TabLayout, TabsTabProps } from 'tamagui'
import { useFormik } from 'formik';

import {

    Button,


    H2,
    Input,
    Label,
    Paragraph,
    SizableText,
    Switch,
    SwitchThumb,
    Tabs, TextArea, Unspaced, XStack, styled,
    useMedia
} from 'tamagui'
import React, { useMemo, useState } from 'react'

import {  YStack } from 'tamagui'


import VCard from 'vcard-creator'
import { t, tg } from '../../util'
import { SelectComponent } from '../../components/ui/SelectComponent'
import { QrCode } from '@tamagui/lucide-icons'
import QRCode from 'react-qr-code'


const geContactCard = ({ firstName, lastName, email, phone, city, postalCode, country, website, address }) => {

    const vcard = new VCard()

    vcard
        .addName(firstName, lastName)
        .addEmail(email)
        .addPhoneNumber(phone)
        .addAddress(undefined, undefined, address, city, undefined, postalCode, country)
        .addURL(website)

    return (vcard.toString())

}
const getWifiDetailString = ({ type, name, password, hidden, eapMethod, anonymousIdetity, identity, phase2Method }) => {
    const e = (s) => s.replaceAll(/[\\,;":]{1,1}/g, "//$&");
    return `WIFI:T:${e(type)};S:${e(name)};P:${e(password)};${hidden ? `H:true;` : ""}${eapMethod ? `E:${e(eapMethod)};` : ""}${anonymousIdetity ? `A:${e(anonymousIdetity)};` : ""}${identity ? `I:${e(identity)};` : ""}${phase2Method ? `PH2:${e(phase2Method)};` : ""}`

}
const getMailto = (params) => {
    const { email, subject, CC, BCC, body } = params;
    const firstQueryParam = `${CC ? "CC" : BCC ? "BCC" : subject ? "subject" : "body"}`;
    const qp = (parameterName) => params[parameterName] ? (parameterName === firstQueryParam) ? `?${parameterName}=${decodeURIComponent(params[parameterName])}` : `&${parameterName}=${decodeURIComponent(params[parameterName])}` : "";

    return `mailto:${email}${qp("CC")}${qp("BCC")}${qp("subject")}${qp("body")}`
}

const getSMSTo = ({ phone, countryCode, message }) => {

    return `sms:${countryCode}${phone}${message ? ":" : ""}${encodeURIComponent(message)}`
}

const facetime = ({ phone, countryCode }) => {

    return `facetime:${countryCode}${phone}`
}
const facetimeAudio = ({ phone, countryCode }) => {

    return `facetime-audio:${countryCode}${phone}`
}
const getCallTo = ({ phone, countryCode, facetime, facetimeAudio }) => {
    if (facetimeAudio && facetime) {
        return facetimeAudio({ phone, countryCode })
    } else if (facetime) {
        return facetime({ phone, countryCode })
    }
    return `tel:${countryCode}${phone}`
}
const suggestEvent = ({ title, timestart, timeend }) => {
    `BEGIN:VEVENT
  SUMMARY:${encodeURIComponent(title)}
  DTSTART:${timestart}
  DTEND:${timeend}
  END:VEVENT
  `
}

const translateText = ({ text }) => {
    return `https://qrtranslate.com/translation/redirect/text?t=${encodeURIComponent(text)}`

}
const sendGeoLocation = ({ longitude, latitude, meterHeight }) => {
    return `geo:${latitude},${longitude},${meterHeight}`
}

const emptyForm ={
    type: "link",
    translate: "",
    link: "http://",
    personal: {
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",

        phone: "",
    },
    business: {
        firstName: "",
        lastName: "",
        email: "",
        website: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        phone: "",
    },
    mailto: {
        email: "",
        subject: "",
        BCC: "",
        CC: "",
        body: ""
            },
    sms: {
        countryCode: "",
        phone: "",
        message: ""
            },
    call: {
        countryCode: "",
        phone: "",
        facetime: false,
        facetimeVoice: false,
    },
    wifi: {
        type: "WPA2",
        name: "",
        password: "",
        hidden: false,
        eapMethod: "",
        anonymousIdetity: "",
        identity: "",
        phase2Method: "",
    }
}

export const Landing = () => {
    const media = useMedia()
    const [tab, setTab] = React.useState("link")
    const [qrCode, setQrCode] = React.useState("")
    const [option, setOption] = useState<"sms" | "personal" | "business" | "call" | "mailto">("personal");
    const [link, setLink] = useState("");
    const formik = useFormik({ initialValues: emptyForm })

        const data = formik.values

    const change = (fieldName:string, data) => {
        const fieldNameArray = fieldName.split(".");
        if(fieldNameArray.length > 1 ){
            formik.setFieldValue(fieldNameArray[0], { ...formik.values[fieldNameArray[0]], [fieldNameArray[1]]: data })
        }else {

            formik.setFieldValue( fieldName, data)
        }
    }




        const generateQR = (task, option, {
            mailto,
            sms,
            call,
            wifi,
            personal, business, translate,
        }) => {
            if (task === "translate") {
                return translateText({ text: translate })
            }
            if (task === "link") {
                return data.link
            }
            if (task === "wifi") {
                return getWifiDetailString(wifi)
            }
            if (task === "contact") {
                if (option === "personal") {

                    return geContactCard(personal);
                }
                if (option === "business") {

                    return geContactCard(business);
                }
                if (option === "mailto") {

                    return getMailto(mailto);
                }
                if (option === "call") {

                    return getCallTo(call);
                }
                if (option === "sms") {

                    return getSMSTo(sms);
                }
            }

        }
    const ButtonComponent = props => <Button {...{ ...props, ...(props.colored ? { backgroundColor: props.yellow ? "#b5e861": "plum" } : {}) }}><SizableText color={props.colored ? "white" : ""}>{props.txt}</SizableText></Button>

    const button = <ButtonComponent onPress={() => { setQrCode(generateQR(tab, option, data)) }} colored txt={ tg`Generate QR Code`}></ButtonComponent>


        return (
            
         <YStack f={1} ai="center" jc="center" gap="$3">
                <H2 size={media.gtSm ? "$10": "$5"}>{t`Make a QR Link`}</H2>
                <Paragraph mt={media.gtSm ?-30:undefined}>{t`Or not, who cares?`}</Paragraph>

                {qrCode ? <YStack gap="$3" style={{ height: "auto", margin: "0 auto", maxWidth: 900, width: "100%" }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={qrCode}
                        viewBox={`0 0 256 256`}
                    />
                    <ButtonComponent colored txt={tg`Make a new QR Code`} onPress={()=>setQrCode("")}></ButtonComponent>
                </YStack> : <YStack minHeight={"40vh"} 
                        style={{ height: "auto", maxWidth: media.gtXs ? "100%" : "90%", width: "100%" }}
                        borderRadius="$4"
                    shadowColor={"black"}
                    shadowOpacity={.2}
                    shadowRadius={40}
                    backgroundColor="$background"
                    padding="$6"
                >
                    <XStack paddingBottom={"$5"}
                
                            width={media.gtXs?700:"100%"}
                        gap="$2"
                        themeShallow
                        borderRadius="$4"
                        position="relative"
                        flexWrap='wrap'
                    >
                        <ButtonComponent
                                colored={tab === "link" ? "plum" : ""}

                            paddingVertical="$2"
                            paddingHorizontal="$3"
                            onPress={() => setTab("link")}
                                txt={t`Link`}

                        >

                        </ButtonComponent>
                        <ButtonComponent
                                colored={tab === "contact" ? "plum" : ""}

                            paddingVertical="$2"
                            paddingHorizontal="$3"
                            onPress={() => setTab("contact")}
                                txt={t`Contact Options`}

                        >
                        </ButtonComponent>
                        <ButtonComponent
                                colored={tab === "wifi" ? "plum" : ""}

                            paddingVertical="$2"
                            paddingHorizontal="$3"
                            onPress={() => setTab("wifi")}
                                txt={t`WiFi Login`}

                        >
                        </ButtonComponent>
                        <ButtonComponent
                                colored={tab==="translate"? "plum": ""}
                            paddingVertical="$2"
                            paddingHorizontal="$3"
                            onPress={() => setTab("translate")}
                                txt={t`Translation`}
                        >
                        </ButtonComponent>
                    </XStack>


                    {{
                "link":<YStack f={1}>
               
                <Input borderColor={"$dark3"} borderWidth={1}
                    miw={200}
                    placeholder={t`Link to a website`}
                    id="url"
                    value={data.link}
                    onChangeText={link => { change("link", link) }}
                />
                            <YStack f={1}/>
                {button}
            </YStack>,
                "contact": <YStack>
                    <XStack alignItems='center'>
                        
                        <Label size="$3" htmlFor="contactOption" paddingRight="$3">
                            {tg`Contact Option`}:
                        </Label>
                        {/* <SelectComponent onValueChange={val => {
                            setOption(val)
                        }} value={option} values={[
                            { id: 2, val: "personal", title: "Contact card" },
                            { id: 3, val: "business", title: "Business contact card" },
                            { id: 5, val: "call", title: "Call" },
                            { id: 4, val: "mailto", title: tg`Send email` },
                            { id: 1, val: "sms", title: "SMS" },
                        ]}></SelectComponent> */}
                            <XStack

                                gap="$2"
                                themeShallow
                                borderRadius="$4"
                                position="relative"
                                width="100%"
                            flexWrap='wrap'

                            >
                                
                                <ButtonComponent
                                yellow
                                colored={option === "personal" ? "plum" : ""}

                                    paddingVertical="$2"
                                    paddingHorizontal="$3"
                                onPress={() => setOption("personal")}
                                txt={t`Contact card`}

                                >

                                </ButtonComponent>
                                <ButtonComponent
                                yellow
                                colored={option === "business" ? "plum" : ""}

                                    paddingVertical="$2"
                                    paddingHorizontal="$3"
                                onPress={() => setOption("business")}
                                txt={t`Business contact card`}

                                >
                                </ButtonComponent>
                                <ButtonComponent
                                yellow
                                    colored={option === "call" ? "plum" : ""}

                                    paddingVertical="$2"
                                    paddingHorizontal="$3"
                                    onPress={() => setOption("call")}
                                txt={t`Call`}

                                >
                                </ButtonComponent>
                                <ButtonComponent
                                yellow
                                    colored={option === "sms" ? "plum" : ""}
                                    paddingVertical="$2"
                                    paddingHorizontal="$3"
                                    onPress={() => setOption("sms")}
                                    txt={t`SMS`}
                                >
                                </ButtonComponent>
                                <ButtonComponent
                                yellow
                                    colored={option === "mailto" ? "plum" : ""}
                                    paddingVertical="$2"
                                    paddingHorizontal="$3"
                                    onPress={() => setOption("mailto")}
                                    txt={t`Email`}
                                >
                                </ButtonComponent>
                            </XStack>
                    </XStack>

                    {(option === "personal" || option === "business") ?
                        <><YStack>
                            <Label size="$3" htmlFor="firstName">
                                {tg`First Name`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`First Name`}
                                id="firstName"
                                value={data[option].firstName}
                                onChangeText={e => change(`${option}.firstName`, e) }
                            />
                        </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="lastName">
                                    {tg`Last Name`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Last Name`}
                                    id="lastName"
                                    value={data[option].lastName}
                                    onChangeText={e => change(`${option}.lastName`, e) }

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="email">
                                    {tg`Email`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Email`}
                                    id="email"
                                    value={data[option].email}
                                    onChangeText={e => change(`${option}.email`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="phone">
                                    {tg`Phone`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Phone`}
                                    id="phone"
                                    value={data[option].phone}
                                    onChangeText={e => change(`${option}.phone`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="website">
                                    {tg`Website`}
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Website`}
                                    id="website"
                                    value={data[option].website}
                                    onChangeText={e => change(`${option}.website`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="address">
                                    {tg`Address`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Address`}
                                    id="address"
                                    value={data[option].address}
                                    onChangeText={e => change(`${option}.address`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="city">
                                    {tg`City`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`City`}
                                    id="city"
                                    value={data[option].city}
                                    onChangeText={e => change(`${option}.city`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="postalCode">
                                    {tg`Postal Code`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Postal Code`}
                                    id="postalCode"
                                    value={data[option].postalCode}
                                    onChangeText={e => change(`${option}.postalCode`, e) }
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="country">
                                    {tg`Country`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Country`}
                                    id="country"
                                    value={data[option].country}
                                    onChangeText={e => change(`${option}.country`, e) }
                                />
                            </YStack>
                        </>
                        : option === "mailto" ? <>
                            <YStack>
                                <Label size="$3" htmlFor="EmailTo">
                                    {tg`Send to Email`}
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Send to Email`}
                                    id="EmailTo"
                                    value={data.mailto.email}
                                    onChangeText={e => change(`mailto.email`, e)}
                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="subject">
                                    {tg`Email Subject`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Email Subject`}
                                    id="subject"
                                    value={data.mailto.subject}
                                    onChangeText={e => change( `mailto.subject`, e) }

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="city">
                                    {tg`Email Message`}:
                                </Label>
                                <TextArea
                                borderColor={"$dark3"}
                                    miw={200}
                                    placeholder={tg`Email Message`}
                                    id="emailBody"
                                    value={data.mailto.body}
                                    onChangeText={e => change(`mailto.body`, e)}

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="BCC">
                                    {tg`BCC`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`BCC`}
                                    id="BCC"
                                    value={data.mailto.BCC}
                                    onChangeText={e => change(`mailto.BCC`, e)}

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="CC">
                                    {tg`CC`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`CC`}
                                    id="CC"
                                    value={data.mailto.CC}
                                    onChangeText={e => change(`mailto.CC`, e)}

                                />
                            </YStack>
                        </> : option === "call" ? <><YStack>
                            <Label size="$3" htmlFor="callPhoneCountryCode">
                                {tg`Country Code`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`Country Code`}
                                id="callPhoneCountryCode"
                                value={data.call.countryCode}
                                    onChangeText={e => change(`call.countryCode`, e)}

                            />
                        </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="callPhone">
                                    {tg`Phone number`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Phone number`}
                                    id="callPhone"
                                    value={data.call.phone}
                                                                        onChangeText={e => change(`call.phone`, e) }

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="callFacetime">
                                    {tg`Facetime`}:
                                </Label>
                                <Switch native id="callFaceTime" checked={data.call.facetime} onCheckedChange={val => change("call.facetime", val)}><Switch.Thumb animation={"quick"}></Switch.Thumb></Switch>

                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="callFacetimeVoice">
                                    {tg`Facetime Voice Only`}:
                                </Label>
                                <Switch native id="callFaceTimeVoice" checked={data.call.facetimeVoice}  onCheckedChange={val => change("call.facetimeVoice", val)}><Switch.Thumb animation={"quick"}></Switch.Thumb></Switch>

                            </YStack>
                        </> : option === "sms" ? <><YStack>
                            <Label size="$3" htmlFor="smsPhoneCountryCode">
                                {tg`Country Code`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`Country Code`}
                                id="smsPhoneCountryCode"
                                value={data.sms.countryCode}
                                                                    onChangeText={e => change(`sms.countryCode`, e) }

                            />
                        </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="smsPhone">
                                    {tg`Phone number`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Phone number`}
                                    id="smsPhone"
                                    value={data.sms.phone}
                                                                        onChangeText={e => change(`sms.phone`, e) }

                                />
                            </YStack>
                            <YStack>
                                <Label size="$3" htmlFor="textMessage">
                                    {tg`Text Message`}:
                                </Label>
                                <Input borderColor={"$dark3"} borderWidth={1}
                                    miw={200}
                                    placeholder={tg`Text Message`}
                                    id="textMessage"
                                    value={data.sms.message}
                                                                        onChangeText={e => change(`sms.message`, e) }

                                />
                            </YStack>
                        </> : null}
                        <YStack paddingTop={"$3"}/>
                        {button}
                        </YStack>,


                "wifi": <YStack f={1}><YStack>
                    <Label size="$3" htmlFor="wifiType">
                        {tg`WiFi Type`}:
                    </Label>
                    <SelectComponent onValueChange={val => change(data => ({ wifi: { ...data.wifi, type: val } }))} value={data.wifi.type} values={[
                        { id: 1, val: "WEP", title: "WEP" },
                        { id: 2, val: "WPA", title: "WPA" },
                        { id: 3, val: "WPA2", title: "WPA2" },
                        { id: 5, val: "WPA3", title: "WPA3" },
                        { id: 4, val: "WPA2-EAP", title: "WPA2-EAP" },
                    ]}></SelectComponent>

                </YStack>
                    <YStack>
                        <Label size="$3" htmlFor="wifiname">
                            {tg`Wifi Name`}:
                        </Label>
                        <Input borderColor={"$dark3"} borderWidth={1}
                            miw={200}
                            placeholder={tg`Wifi Name`}
                            id="wifiname"
                            value={data.wifi.name}
                            onChangeText={val => change("wifi.name", val)}
                        />
                    </YStack>
                    <YStack>
                        <Label size="$3" htmlFor="wifiPassword">
                            {tg`WiFi Password`}:
                        </Label>
                        <Input borderColor={"$dark3"} borderWidth={1}
                            textContentType="password"
                            miw={200}
                            placeholder={tg`WiFi Password`}
                            id="wifiPassword"
                            value={data.wifi.password}
                            onChangeText={val => change("wifi.password", val)}
                        />
                    </YStack>
                    {data.wifi.type === "WPA2-EAP" ? <><YStack>
                        <Label size="$3" htmlFor="hidden">
                            {tg`WiFi Hidden`}:
                        </Label>
                        <Switch id="hidden" checked={data.wifi.hidden}  onCheckedChange={val => change("wifi.hidden", val)}><SwitchThumb animation={"quick"}></SwitchThumb></Switch>
                    </YStack>
                        <YStack>
                            <Label size="$3" htmlFor="eapMethod">
                                {tg`EAP Method`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`EAP Method`}
                                id="eapMethod"
                                value={data.wifi.eapMethod}
                                onChangeText={val => change("wifi.eapMethod", val)}
                            />
                        </YStack>
                        <YStack>
                            <Label size="$3" htmlFor="anonymousIdetity">
                                {tg`Anonymous Identity`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`Anonymous Identity`}
                                id="anonymousIdetity"
                                value={data.wifi.anonymousIdetity}
                                onChangeText={val => change("wifi.anonymousIdetity", val)}
                                />
                        </YStack>
                        <YStack>
                            <Label size="$3" htmlFor="identity">
                                {tg`Identity`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                miw={200}
                                placeholder={tg`Identity`}
                                id="identity"
                                value={data.wifi.identity}
                                onChangeText={val => change("wifi.identity", val)}
                            />
                        </YStack>
                        <YStack>
                            <Label size="$3" htmlFor="identity">
                                {tg`WiFi Phase2 Method`}:
                            </Label>
                            <Input borderColor={"$dark3"} borderWidth={1}
                                textContentType="password"
                                miw={200}
                                placeholder={tg`Identity`}
                                id="phase2Method"
                                value={data.wifi.phase2Method}
                                onChangeText={val => change("wifi.phase2Method", val)}
                            />
                        </YStack></>
                        : null
                    }
                    <YStack paddingTop={"$3"} f={1}/>
                    {button}
                </YStack>
                , "translate": <YStack f={1}>
                    <Label size="$3" htmlFor="translate">
                        {tg`Text Translation`}:
                    </Label>
                    <TextArea
                    borderColor={"$dark3"}
                        miw={200}
                        placeholder={tg`Type something.`}
                        id="translate"
                        value={data.translate}
                        onChangeText={e => change("translate", e )}
                    />
                    <YStack f={1} />

                    {button}
                </YStack>
            }[tab]}
                        
                        
                </YStack>}
            </YStack>
    )
}

