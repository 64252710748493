import '@tamagui/core/reset.css'
import './style.css'


import { TamaguiProvider, YStack } from 'tamagui'

import config from './tamagui.config'
import { Landing, Privacy } from './pages'

export const Root = () =>{

  return (
    <TamaguiProvider config={config} defaultTheme="light">
      <YStack f={1} ai="center" jc="center" gap="$3">
          <Landing/>
        <YStack zIndex={-1} fullscreen className='bg' />
      </YStack></TamaguiProvider>
  )
}

