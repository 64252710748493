import { Check, ChevronDown } from "@tamagui/lucide-icons";
import { Adapt, Select, Sheet } from "tamagui";
import React, { useState } from 'react';
import { ChevronUp } from '@tamagui/lucide-icons';
export type SelectProps = { value, onValueChange: (val: any) => any, placeholder: string, values: { id: number, val: any, title:any}[] };

import { YStack } from 'tamagui';

export const SelectComponent:React.FC<SelectProps> = (props) => {

    const { value, onValueChange, placeholder, values } = props;

        return (
            <Select disablePreventBodyScroll {...props}>
                <Select.Trigger width={220} iconAfter={ChevronDown}>
                    <Select.Value placeholder={placeholder} />
                </Select.Trigger>

                <Adapt when="sm" platform="touch">
                    <Sheet
                        native={true}

                        dismissOnSnapToBottom
                        animationConfig={{
                            type: 'spring',
                            damping: 20,
                            mass: 1.2,
                            stiffness: 250,
                        }}
                    >
                        <Sheet.Frame>
                            <Sheet.ScrollView>
                                <Adapt.Contents />
                            </Sheet.ScrollView>
                        </Sheet.Frame>
                        <Sheet.Overlay
                            enterStyle={{ opacity: 0 }}
                            exitStyle={{ opacity: 0 }}
                        />
                    </Sheet>
                </Adapt>

                <Select.Content zIndex={200000}>
                    <Select.ScrollUpButton
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        width="100%"
                        height="$3"
                    >
                        <YStack zIndex={10}>
                            <ChevronUp size={20} />
                        </YStack>
                        <YStack
                            fullscreen
                            borderRadius="$4"
                        />
                    </Select.ScrollUpButton>

                    <Select.Viewport
                        // to do animations:
                        // animation="quick"
                        // animateOnly={['transform', 'opacity']}
                        // enterStyle={{ o: 0, y: -10 }}
                        // exitStyle={{ o: 0, y: 10 }}
                        minWidth={200}
                    >
                        <Select.Group>
                            <Select.Label>Languages</Select.Label>
                            {/* for longer lists memoizing these is useful */}
                            {React.useMemo(
                                () =>
                                    values.map((item, i) => {
                                        return (
                                            <Select.Item
                                                index={i}
                                                key={item.val}
                                                value={item.val}
                                            >
                                                <Select.ItemText>{item.title}</Select.ItemText>
                                                <Select.ItemIndicator marginLeft="auto">
                                                    <Check size={16} />
                                                </Select.ItemIndicator>
                                            </Select.Item>
                                        )
                                    }),
                                [values]
                            )}
                        </Select.Group>
                        {/* Native gets an extra icon */}
                     
                    </Select.Viewport>

                    <Select.ScrollDownButton
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        width="100%"
                        height="$3"
                    >
                        <YStack zIndex={10}>
                            <ChevronDown size={20} />
                        </YStack>
                        <YStack
                          
                            fullscreen
                            borderRadius="$4"
                        />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select>
        )
    }
