


export const t = (text: TemplateStringsArray, ...options) => {

    return text.map((t, i) => t + (options && options[i] || "")).join("")
}


export const tg = (text: TemplateStringsArray, ...options) => {

    return text.map((t, i) => t + (options && options[i] || "")).join("")
}

